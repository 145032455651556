import React, {useRef} from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import { Container } from '@mui/material'
import interactionPlugin from '@fullcalendar/interaction'
import { getSnkrGarageProducts } from '../lib/getSnkrGarageProducts';
import { IProduct } from '../models/interfaces/product'
import { BrandEventColor } from '../models/enums/brandEventColor'
import { IEvent } from '../models/interfaces/event'
import { IMongoProduct } from "../models/interfaces/mongodb/product";
import ProductList from '../components/productList';
import { formatStringDateToYearMonthDateString } from '../lib/formatStringDateToYearMonthDateString'

export interface Props {
    events: Array<IEvent>,
    products: Map<string, Array<IProduct>>
}

export default function Calendar({ events, products }: Props) {
    const calendarRef = useRef<any>(null);
    const [currentStartDate, setCurrentStartDate] = React.useState<string>()

    const filterSelectedProducts = (products: Object) => {
        let filteredProducts = new Array<IProduct>
        const map = new Map(Object.entries(products));

        if (currentStartDate && map.has(currentStartDate)) {
            filteredProducts = map.get(currentStartDate)
        }

        return filteredProducts
    }

    function changeSelectedDate(info: any) {
        setCurrentStartDate(info.dateStr)
    }

    function viewDidMount(info: any) {
        let today = formatStringDateToYearMonthDateString(new Date().toString())
        setCurrentStartDate(today)
    }

    let results = filterSelectedProducts(products)

    function prevButtonClick() {
        let calendarApi = calendarRef.current?.getApi()
        calendarApi.prev()

        let currentStartDate = formatStringDateToYearMonthDateString(calendarApi.view.currentStart.toUTCString())
        setCurrentStartDate(currentStartDate)
        calendarApi.select(currentStartDate)
    }

    function nextButtonClick() {
        let calendarApi = calendarRef.current?.getApi()
        calendarApi.next()

        let currentStartDate = formatStringDateToYearMonthDateString(calendarApi.view.currentStart.toUTCString())
        setCurrentStartDate(currentStartDate)
        calendarApi.select(currentStartDate)
    }

    function resizeCalendarView(arg: any) {
        let calendarApi = calendarRef.current?.getApi()

        if (window.innerWidth < 514) {
            calendarApi.changeView('dayGridDay', currentStartDate)
        } else {
            calendarApi.changeView('dayGridWeek', currentStartDate)
            calendarApi.select(currentStartDate)
        }
    }

    function initialView() {
        if (typeof window !== "undefined") {
            return window.innerWidth < 514 ? "dayGridDay" : "dayGridWeek"
        }
    }

    return (
        <Container sx={{pt:2, pb: 10}}>
            <FullCalendar
                initialView={initialView()}
                windowResize={resizeCalendarView}
                ref={calendarRef}
                plugins={[ 
                    dayGridPlugin,
                    interactionPlugin, 
                ]}
                customButtons= {{
                    prevButton: {
                        hint: 'Click for previous week',
                        click: prevButtonClick,
                        icon: 'chevron-left',
                    },
                    nextButton: {
                        hint: 'Click for next week',
                        click: nextButtonClick,
                        icon: 'chevron-right',
                    }
                }}
                headerToolbar={{
                    left: 'prevButton,nextButton',
                    center: 'title',
                    right: ''
                }}
                selectable={true}
                nowIndicator={true}
                events={events}
                height={"auto"}
                dateClick={changeSelectedDate}
                viewDidMount={viewDidMount}
            />
            <ProductList products={results}></ProductList>
        </Container>
      );
}

export async function getStaticProps() {
    let mongoProducts:Array<IMongoProduct> = await getSnkrGarageProducts(false)
    let uiProductsMap:Map<string, Array<IProduct>> = convertMongoProductToUIProduct(mongoProducts);
    uiProductsMap = JSON.parse(JSON.stringify(Object.fromEntries(uiProductsMap)));

    let events = await convertProductsToEvents(mongoProducts)
    return {
      props: { events, products: uiProductsMap },
      revalidate: 43200 // revalidate twice a day
    };
}

function convertMongoProductToUIProduct(products : Array<IMongoProduct>) {
    let uiProductMap = new Map<string, Array<IProduct>>()
    for (let i in products) {
        let uiProduct = {
            name: products[i].name,
            styleColor: products[i].styleColor,
            link: products[i].link,
            imageUrl: products[i].imageUrl,
            releaseDate: products[i].releaseDate,
            releaseDateMonthDay: products[i].releaseDateMonthDay,
            price: products[i].price,
            brand: products[i].brand,
            styleName: products[i].styleName,
            colorDescription: products[i].colorDescription
        }
        let productDateString = formatStringDateToYearMonthDateString(products[i].releaseDate) 

        if (uiProductMap.has(productDateString)) {
            uiProductMap.get(productDateString)?.push(uiProduct)
        } else {
            uiProductMap.set(productDateString, [uiProduct])
        }
    }
    return uiProductMap;
  }

  async function convertProductsToEvents(products: Array<IProduct>) {
    let events = new Array<IEvent>
    for(let i in products) {
        let event = {
            title: products[i].name + " " + products[i].styleName + " '" + products[i].styleColor + "'",
            date: products[i].releaseDate,
            styleColor: products[i].styleColor,
            color: getColorByBrand(products[i]),
            display: "list-item", // this determines what color attributes you can use 'backgroundColor, etc'
        }
        // @ts-ignore
        events.push(event)
    }    
    return events
}

function getColorByBrand(product: IProduct) {
    switch (product.brand) {
        case "SNKRS":
            return BrandEventColor.SNKRS;
        case "Adidas":
            return BrandEventColor.Adidas;
        case "NewBalance":
            return BrandEventColor.NewBalance;
        default:
            return ""            
    }
}