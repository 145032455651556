import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import styles from '../styles/Dashboard.module.scss';
import { CardActionArea } from '@mui/material';
import { Props } from '../pages/dashboard';

export default function ProductList({products}: Props) {
    return (
        <Grid container spacing={4} sx={{p: 2}}>
          {products.map((data) => (
            <Grid item xs={12} sm={4} key={data.styleColor}>
              <Card className={styles.card}>
                <CardActionArea href={data.link} target="_blank" rel="noopener noreferrer">
                  <CardMedia
                    component="img"
                    image={data.imageUrl}
                    alt="Sneaker Product"
                  />
                  <CardContent>
                    <Typography align='center' gutterBottom variant="caption" component="div">
                      {data.name} {data.styleName ? <div>&quot;{data.styleName}&quot;</div> : null}
                    </Typography>
                    <Grid container>
                      <Grid container justifyContent={'space-around'}>
                        <Typography align='left' variant="subtitle2" color="text.secondary">
                          {data.releaseDateMonthDay}
                        </Typography>
                        <Typography align='center' variant="subtitle2" color="text.secondary">
                          ${data.price}
                        </Typography>
                        <Typography align='right' variant="subtitle2" color="text.secondary">
                          {data.styleColor}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
    )
}